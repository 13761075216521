import {
    Adventure,
    Board,
    Changes,
    Clip,
    DataType,
    Identity,
    Information,
    Journal,
    JournalEntry,
    Media, Pin,
    Player,
    Scene,
    Session,
    Stage, Tracker, User
} from "../../repository/domain/ApiTypes";
import {TypedMap} from '../../repository/domain/MapTypes.ds';
import {AppState} from "../../repository/state/AppState";
import {AppMode, UiState} from "../../repository/state/UiState";
import {Game} from '../../repository/domain/ApiTypes';

/**
 * Unpacks arrays of session and entry data to the maps stored in the AppState
 */
export const unpackSessionForState = (sessions: Session[], entries: JournalEntry[]) => {
    const targetSessionsMap: TypedMap<Session> = {};
    const targetEntryMap: TypedMap<JournalEntry> = {};

    sessions.forEach(s => {
        targetSessionsMap[s.id] = s;
    });

    entries.forEach(e => targetEntryMap[e.id] = e);

    return {sessions: targetSessionsMap, entries: targetEntryMap}
};

/**
 * Calculates a new state based on the given state with the given objects updated appropriately
 * @param state the current state
 * @param changedObjects the changes to apply to the state
 * @param uiChanges changes for the user interface
 * TODO clean this up; too much nearly duplicate code here
 */
export const updateStateWithObjects = (state: AppState, changedObjects: Identity[], uiChanges?: Partial<UiState>) : AppState =>
    updateStateWithChanges(state, {others: changedObjects} as Changes, uiChanges);

/**
 * Calculates a new state based on the given state with the given objects updated appropriately
 * @param state the current state
 * @param changes the changes to apply to the state
 * @param uiChanges changes for the user interface
 * TODO clean this up; too much nearly duplicate code here
 */
export const updateStateWithChanges = (state: AppState, changes: Changes, uiChanges?: Partial<UiState>) : AppState => {
    let updatedAdventure = state.adventure
    let updatedGame = state.game
    let updatedPlayer = state.player
    let updatedParticipantsPlayers = state.participantPlayers
    let updatedParticipantsUsers = state.participantUsers
    let updatedClips = state.clips
    let updatedInformation = state.information
    let updatedMedia = state.media
    let updatedJournal = state.journal
    let updatedSessions = state.sessions
    let updatedEntries = state.entries
    let updatedBoards = state.boards
    let updatedPins = state.pins
    let updatedStage = state.stage
    let updatedScenes = state.scenes
    let updatedTrackers = state.trackers
    let updatedUser = state.user

    let updatedObjects = changes.others || [] as Identity[]
    if(changes.primary) {updatedObjects = updatedObjects.concat(changes.primary)}
    const deletedObjects = changes.deletions ? changes.deletions : [];
    const uiUpdates = uiChanges ? uiChanges : {}

    const newUiState = {
        ...state.ui,
        ...uiUpdates
    }

    for(const o of updatedObjects) {
        if(o.type === DataType.adventure) {
            updatedAdventure = o as Adventure
        }
        else if(o.type === DataType.game) {
            updatedGame = o as Game
        }
        else if(o.type === DataType.player) {
            const p = o as Player
            if(updatedPlayer && updatedPlayer.id === p.id) {
                updatedPlayer = p
            }
            if(newUiState.mode === AppMode.PLAY_GAME || newUiState.mode === AppMode.EDIT_PLAYER) {
                if(!updatedPlayer || p.id === updatedPlayer?.id) updatedPlayer = p
            }
            if(updatedParticipantsPlayers === state.participantPlayers) {
                updatedParticipantsPlayers = {...state.participantPlayers}
            }
            updatedParticipantsPlayers[p.id] = p
        }
        else if(o.type === DataType.information) {
            if(updatedInformation === state.information) {
                updatedInformation = {...state.information};
            }
            updatedInformation[o.id] = o as Information;
        }
        else if(o.type === DataType.media) {
            if(updatedMedia === state.media) {
                updatedMedia = {...state.media};
            }
            updatedMedia[o.id] = o as Media;
        }
        else if(o.type === DataType.character || o.type === DataType.location || o.type === DataType.plot)  {
            if(updatedClips === state.clips) {
                updatedClips = {...state.clips};
            }
            updatedClips[o.id] = o as Clip;
        }
        else if(o.type === DataType.journal) {
            updatedJournal = o as Journal
        }
        else if(o.type === DataType.session) {
            if(updatedSessions === state.sessions) {
                updatedSessions = {...state.sessions};
            }
            updatedSessions[o.id] = o as Session;
        }
        else if(o.type === DataType.entry) {
            if(updatedEntries === state.entries) {
                updatedEntries = {...state.entries};
            }
            updatedEntries[o.id] = o as JournalEntry;
        }
        else if(o.type === DataType.board) {
            if(updatedBoards === state.boards) {
                updatedBoards = {...state.boards};
            }
            updatedBoards[o.id] = o as Board;
        }
        else if(o.type === DataType.tracker) {
            if(updatedTrackers === state.trackers) {
                updatedTrackers = {...state.trackers};
            }
            updatedTrackers[o.id] = o as Tracker;
        }
        else if(o.type === DataType.stage) {
            updatedStage = o as Stage;
        }
        else if(o.type === DataType.scene) {
            if(updatedScenes === state.scenes) {
                updatedScenes = {...state.scenes};
            }
            updatedScenes[o.id] = o as Scene;
        }
        else if(o.type === DataType.pin) {
            if(updatedPins === state.pins) {
                updatedPins = {...state.pins};
            }
            updatedPins[o.id] = o as Pin;
        }
        else if(o.type === DataType.user) {
            if(o.id === updatedUser?.id) updatedUser = o as User

            if(updatedParticipantsUsers === state.participantUsers) {
                updatedParticipantsUsers = {...state.participantUsers}
            }
            updatedParticipantsUsers[o.id] = o as User


        }
        else {
            console.log(`Object ${JSON.stringify(o)} could not be classified for updating in store`);
        }
    }

    for(const o of deletedObjects) {
        if(o.type === DataType.player) {
            if(updatedParticipantsPlayers) {
                if(updatedParticipantsPlayers === state.participantPlayers) {
                    updatedParticipantsPlayers = {...state.participantPlayers};
                }
                delete updatedParticipantsPlayers[o.id];
            }
        }
        else if(o.type === DataType.information) {
            if(updatedInformation) {
                if(updatedInformation === state.information) {
                    updatedInformation = {...state.information};
                }
                delete updatedInformation[o.id];
            }
        }
        else if(o.type === DataType.media) {
            if(updatedMedia) {
                if(updatedMedia === state.media) {
                    updatedMedia = {...state.media};
                }
                delete updatedMedia[o.id];
            }
        }
        else if(o.type === DataType.character || o.type === DataType.location || o.type === DataType.plot)  {
            if(updatedClips) {
                if(updatedClips === state.clips) {
                    updatedClips = {...state.clips};
                }
                delete updatedClips[o.id];
            }
        }
        else if(o.type === DataType.session) {
            if(updatedSessions) {
                if(updatedSessions === state.sessions) {
                    updatedSessions = {...state.sessions};
                }
                delete updatedSessions[o.id];
            }
        }
        else if(o.type === DataType.entry) {
            if(updatedEntries) {
                if(updatedEntries === state.entries) {
                    updatedEntries = {...state.entries};
                }
                delete updatedEntries[o.id];
            }
        }
        else if(o.type === DataType.board) {
            if(updatedBoards) {
                if(updatedBoards === state.boards) {
                    updatedBoards = {...state.boards};
                }
                delete updatedBoards[o.id];
            }
        }
        else if(o.type === DataType.stage) {
            updatedStage = o as Stage;
        }
        else if(o.type === DataType.scene) {
            if(updatedScenes) {
                if(updatedScenes === state.scenes) {
                    updatedScenes = {...state.scenes};
                }
                delete updatedScenes[o.id];
            }
        }
        else if(o.type === DataType.tracker) {
            if(updatedTrackers) {
                if(updatedTrackers === state.trackers) {
                    updatedTrackers = {...state.trackers};
                }
                delete updatedTrackers[o.id];
            }
        }
        else if(o.type === DataType.pin) {
            if(updatedPins) {
                if(updatedPins === state.pins) {
                    updatedPins = {...state.pins};
                }
                delete updatedPins[o.id];
            }
        }
        else {
            console.log(`Object ${JSON.stringify(o)} could not be classified for updating in store`);
        }
    }

    return {
        ...state,
        adventure: updatedAdventure,
        game: updatedGame,
        player: updatedPlayer,
        participantPlayers: updatedParticipantsPlayers,
        participantUsers: updatedParticipantsUsers,
        clips: updatedClips,
        information: updatedInformation,
        media: updatedMedia,
        journal: updatedJournal,
        sessions: updatedSessions,
        entries: updatedEntries,
        boards: updatedBoards,
        pins: updatedPins,
        stage: updatedStage,
        scenes: updatedScenes,
        trackers: updatedTrackers,
        user: updatedUser,
        ui: newUiState,
    }
};

