import createCachedSelector from "re-reselect";
import {createSelector} from "reselect";
import {DataNotLoadedException} from "../../common/exception/Exceptions";
import {Clip, DataType, Identity} from "../../repository/domain/ApiTypes";
import {TypedMap} from "../../repository/domain/MapTypes.ds";
import {
    characterDataService,
    clipDataService,
    ClipDataService,
    locationDataService,
    plotDataService
} from "../../repository/service/ClipDataService";
import {AppState} from "../../repository/state/AppState";
import {getUiState} from "../../repository/UiFunctions";
import {isPlayer} from "../player/PlayerFunctions";
import {isStringEmpty} from "../../common/function/StringFunctions";

export const isClip = (identity: Identity | null | undefined): boolean =>
    (identity !== null && identity !== undefined) &&
    (identity.type === DataType.character ||
        identity.type === DataType.plot ||
        identity.type === DataType.location ||
        identity.type === DataType.clip);

export const isClipProxy =  (identity: Identity | null | undefined): boolean =>
    (identity !== null && identity !== undefined) && identity.type === DataType.clipProxy;

export const hasTrackers = (identity: Identity | null | undefined): boolean =>
    (identity !== null && identity !== undefined) && (isClip(identity) || isClipProxy(identity) || isPlayer(identity))

export const filterClip = (identities: Identity[]) => identities.filter(c => isClip(c)).map(c => c as Clip)

export const getCorrectClipName = (clip: Clip, preferDescriptiveName: boolean) =>
    preferDescriptiveName && !isStringEmpty(clip.descriptiveName) ? clip.descriptiveName : clip.name

export const getAllClipsMap = (state: AppState): TypedMap<Clip> => {
    if (state.clips) {
        return state.clips;
    }
    throw new DataNotLoadedException()
};

/**
 * Returns the clip data service for the appropriate clip type
 */
export const getClipServices = (clipType?: DataType): ClipDataService => {
    if (!clipType) return clipDataService;

    if (clipType === DataType.character) return characterDataService;
    if (clipType === DataType.location) return locationDataService;
    if (clipType === DataType.plot) return plotDataService;

    throw new DataNotLoadedException()
};

/**
 * Gets a single clip
 * @param state
 * @param clipId
 */
export const getClip = createCachedSelector(
    getAllClipsMap,
    (state: AppState, clipId: string) => clipId,
    (allClips, clipId) => allClips[clipId]
)(
    (_, clipId) => clipId
);

/**
 * Converts the clip's type to a string with an initial capital letter
 */
export const getClipTypeName = (clip: Clip) =>
    `${clip.type.substr(0, 1).toUpperCase()}${clip.type.substring(1).toLowerCase()}`


/**
 * Returns all clips in an array
 */
export const getClipsAsArray = createSelector(
    [getAllClipsMap],
    (clips: TypedMap<Clip>) => Object.values(clips)
);

/**
 * Returns the ID of the selected clip
 */
export const getEditingClipId = createSelector(
    [getUiState],
    (uiState) => uiState.selectedClipId !== null ? uiState.selectedClipId : null
);

/**
 * Returns the value of the selected clip
 */
export const getClipBeingEditedOrNull: (state: AppState) => Clip | null = createSelector(
    [getAllClipsMap, getEditingClipId],
    (clipMap, selectedClipId) => selectedClipId ? clipMap[selectedClipId] : null
);


export const getClipParts = (state: AppState, clipId: string): Clip[] => {
    return getClip(state, clipId).parts.map(c => getClip(state, clipId))
};

export const getSelectedClipParts = createSelector(
    [getAllClipsMap, getClipBeingEditedOrNull],
    (clipMap: TypedMap<Clip>, selectedClip: Clip | null) =>
        selectedClip
            ? selectedClip.parts.map(part => clipMap[part.id])
            : []
);

/**
 * Searches the given clip map for any clips whose name matches the given text. Currently, this is very simple.
 */
export const searchClips = (allClips: TypedMap<Clip> | undefined, searchText: string): Clip[] => {
    if(!allClips) return []
    const regExp = new RegExp(searchText, "i");
    return Object.values(allClips).filter(c => (c.name && c.name.match(regExp)) || (c.description && c.description.match(regExp)))
}

/**
 * Returns true if the object is a Plot
 */
export const isPlot = (obj: Identity) =>
    obj.type === DataType.plot

/**
 * Returns true if the object is a Character
 */
export const isCharacter = (obj: Identity) =>
    obj.type === DataType.character

/**
 * Returns true if the object is a Location
 */
export const isLocation = (obj: Identity) =>
    obj.type === DataType.location