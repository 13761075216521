import BaseRepository from "../BaseRepository";
import { Clip, DataType, Identity, PartialClip, PartialInformationReference, PartialMediaReference } from "../domain/ApiTypes";

/**
 * A service to provide data services for accessing Clip objects
 */
export class ClipDataService {
    private readonly repository: BaseRepository;

    constructor(dataType: DataType) {
        this.repository = new BaseRepository(dataType);
    }

    /**
     * Find a single item with the given id
     * @param {string} id the ID to find
     * @return {Clip} a promise with the item that was found
     */
    public findOne = (id: string) => {
        return this.repository.findSingleItem(id);
     };

    /**
     * When the user changes the value of a field, this saves the change to the database and propagates it across the UI,
     * if necessary
     * @param itemId ID of item to be updated
     * @param updates the update Partial object
     */
    public updateItemFields = (itemId: string, updates: any) => { // TODO fix event type
        // TODO because of the drawer and how the editor fires events, I think, events are sometimes fired with a null item
        return this.repository.updateItem(itemId, updates)
    };

    /**
     * Adds a new clip
     * @returns a promise returning the updated item
     */
    public addNewInCollectionWithDetails = (owner: Identity, partial: PartialClip) => {
        return this.repository.createInCollectionWithDetails(owner, partial)
    };

    /**
     * Adds a new plot item as a part of the given item
     * @param item the item that is to be added to
     * @returns a promise returning the updated item
     */
    public addNewAsPart = (adventureId: string, item: Clip) => {
        return this.repository.addNewPartInAdventure(item.id, adventureId)
    };

    /**
     * Adds an existing plot item as a part of the given item
     * @param item the item that is to be added to
     * @param partId the ID of the part to be added
     * @returns a promise with the updated items
     */
    public addExistingPartToPlot(item : Clip, partId : string) {
        return this.repository.addExistingAsPart(item.id, partId)
    }

    /**
     * Removes an item from being part of the given item
     * @param id the ID to be removed
     * @returns a promise with the updated items
     */
    public delete(id: string) {
        return this.repository.deleteItem(id)
    }

    /**
     * Removes an item from being part of the given item
     * @param item the item that is to be removed from
     * @param partId the ID of the part to be removed
     * @returns a promise with the updated items
     */
    public removePart(item : Clip, partId : string) {
        return this.repository.removePartFromPlot(item.id, partId)
    }

    /**
     * Adds the given media to the given clip
     * @param clipId the ID of clip that is to be added to
     * @param mediaId the ID of the media to be added
     * @returns a promise with the updated item
     */
    public addMedia(clipId: string, mediaId : string) {
        return this.repository.addMedia(clipId, mediaId)
    }

    /**
     * Updates the data on the given media reference
     */
    public updateMediaReference(clipId: string, mediaId : string, updates: PartialMediaReference) {
        return this.repository.updateMediaReference(clipId, mediaId, updates)
    }

    /**
     * Removes the given media from the given clip
     * @param clipId the ID of clip that is to be added to
     * @param mediaId the ID of the media to be added
     * @returns a promise with the updated item
     */
    public removeMedia(clipId: string, mediaId : string) {
        return this.repository.removeMedia(clipId, mediaId)
    }

    /**
     * Adds the given information to the given clip
     * @param clipId the ID of clip that is to be added to
     * @param informationId the ID of the information to be added
     * @returns a promise with the updated item
     */
    public addInformation(clipId: string, informationId : string) {
        return this.repository.addInformation(clipId, informationId)
    }

    /**
     * Updates the data on the given information reference
     */
    public updateInformationReference(clipId: string, informationId : string, updates: PartialInformationReference) {
        return this.repository.updateInformationReference(clipId, informationId, updates)
    }

    /**
     * Removes the given information from the given clip
     * @param clipId the ID of clip that is to be added to
     * @param informationId the ID of the media to be added
     * @returns a promise with the updated item
     */
    public removeInformation(clipId: string, informationId : string) {
        return this.repository.removeInformation(clipId, informationId)
    }

}

export const clipDataService = new ClipDataService(DataType.clip)
export const characterDataService = new ClipDataService(DataType.character)
export const locationDataService = new ClipDataService(DataType.location)
export const plotDataService = new ClipDataService(DataType.plot)
